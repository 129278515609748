<template>
	<section id="features" class="py-15" style="background-color: #3f5fb9;">
		<v-container fluid>
			<h3 class="text-h3 text-center mb-2" style="color: white">
				Ventajas
			</h3>
			<div class="mx-auto my-8 d-flex flex-wrap justify-space-around" style="max-width: 1000px">
				<v-card dark max-width="500" class="mx-auto" color="transparent" flat>
					<v-card-title class="text-h4" style="word-break: break-word">
						Universitarios o recién graduados
					</v-card-title>
					<v-card-subtitle class="text-h6 font-weight-bold">
						Talento joven
					</v-card-subtitle>
					<v-card-text class="text-body-1 text-justify">
						Los estudiantes en su primera etapa de la carrera se unen a nuestra comunidad y avanzan en sus estudios con la ayuda de OverSOS y de sus
						compañeros. Una vez llegan a su etapa final y entran al mundo laboral, tendrán a su disposición ofertas de empresas interesadas en un
						estudiante de último curso o graduado. La empresa no tiene que perder tiempo descartando candidatos que no cumplen el perfil buscado, por
						lo que brindamos la solución si hay interés en talento joven.
					</v-card-text>
				</v-card>
				<div class="ma-auto">
					<v-img class="ma-auto" contain width="350" src="@/assets/images/landing/feature-1.svg" />
				</div>
			</div>

			<div class="mx-auto my-8 d-flex flex-wrap flex-row-reverse justify-space-around" style="max-width: 1000px">
				<v-card dark max-width="500" class="mx-auto" color="transparent" flat>
					<v-card-title class="text-h4" style="word-break: break-word">
						Focalización
					</v-card-title>
					<v-card-subtitle class="text-h6 font-weight-bold">
						División por estudios
					</v-card-subtitle>
					<v-card-text class="text-body-1 text-justify">
						OverSOS se divide por universidades y estudios. Tenemos una comunidad muy grande en ingeniería industrial y nos estamos expandiendo
						actualmente a muchas más ramas de ingeniería. Al diferenciar entre estudios, es posible focalizar las ofertas de una empresa a un grupo
						determinado de usuarios que cursen los estudios de interés para la oferta. De este modo, si la empresa busca estudiantes con unos estudios
						determinados, no recibirá solicitudes de candidatos que no cumplan los requisitos básicos.
					</v-card-text>
				</v-card>
				<div class="ma-auto">
					<v-img class="ma-auto" contain width="350" src="@/assets/images/landing/feature-2.png" />
				</div>
			</div>

			<div class="mx-auto my-8 d-flex flex-wrap justify-space-around" style="max-width: 1000px">
				<v-card dark max-width="500" class="mx-auto" color="transparent" flat>
					<v-card-title class="text-h4" style="word-break: break-word">
						Filtrado adicional
					</v-card-title>
					<v-card-subtitle class="text-h6 font-weight-bold">
						Búsqueda específica
					</v-card-subtitle>
					<v-card-text class="text-body-1 text-justify">
						Una oferta está normalmente enfocada a un grupo determinado de personas concreto según aptitudes y afinidades de cada candidato y el tipo
						de oferta ofrecida. Se pueden categorizar las ofertas en distintas clases según sean prácticas, primera experiencia laboral o si se
						requiere algo de experiencia previa, entre otras. El estudiante, a la hora de buscar ofertas, podrá utilizar estos filtros para agilizar
						la búsqueda y permitir una mejor afinidad, como mostrando las ofertas que incluyen los sectores de interés del usuario.
					</v-card-text>
				</v-card>
				<div class="ma-auto">
					<v-img class="ma-auto" contain width="350" src="@/assets/images/landing/feature-3.png" />
				</div>
			</div>
		</v-container>
	</section>
</template>

<script>
export default {
	name: 'LandinFeatures'
}
</script>
